import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import AccountSection from "../AccountSection";
import Divider from "../Divider";
import { Button } from "react-bootstrap";
import menuSearchButton from "../logos/message-search.png";
interface LayoutProps {
  children: React.ReactNode;
  aside?: () => JSX.Element;
}

const ChatLayout: React.FC<LayoutProps> = ({ children, aside }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  const handleClickOutside = (event: React.MouseEvent) => {
    if (
      isMenuOpen &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
    }
  };

  return (
    <div className="relative flex-col" onClick={handleClickOutside}>
      <header className="w-full fixed top-0 right-0 z-10 bg-white border border-layout-background drop-shadow-md lg:drop-shadow-none">
        <Button onClick={toggleMenu} className="lg:hidden fixed py-5 px-2 z-20">
          {!isMenuOpen && <img src={menuSearchButton} alt="Open Menu" />}
        </Button>
        <Link
          className="lg:hidden flex flex-col items-center py-5 px-2"
          to="/chat/dashboard"
        >
          <img src="../henna.png" alt="henna" />
        </Link>
        <div className="sm:w-[75px] lg:w-[250px]">
          <AccountSection />
        </div>
      </header>

      <div className="flex flex-col lg:flex-row min-h-screen">
        <div className="w-[250px] flex shrink-0">
          <span></span>
          <aside
            ref={menuRef}
            className={`bg-layout-background lg:bg-white flex flex-col w-[250px] gap-4 fixed lg:p-0 lg:flex ${
              isMenuOpen
                ? `h-full z-10 rounded-r-2xl drop-shadow-xl lg:rounded-none lg:drop-shadow-none`
                : "hidden"
            }`}
          >
            <Button
              onClick={toggleMenu}
              className="lg:hidden pt-5 pb-2 px-2 z-20"
            >
              <img src="./arrow-circle-left.png" alt="Close Menu" />
            </Button>
            <Link
              className="hidden lg:flex lg:flex-col lg:items-center"
              to="/chat/dashboard"
            >
              <img src="../henna.png" alt="henna" className="mt-4 " />
            </Link>
            <Divider className={"hidden lg:flex lg:w-10/12 lg:mb-[16px] "} />
            <div className="pl-3">{aside && <aside>{aside()}</aside>}</div>
          </aside>
        </div>
        <div className="lg:hidden w-full h-20"></div>
        <Divider className="lg:hidden"></Divider>
        <main className="flex-grow lg:bg-layout-background flex flex-col items-center">
          {children}
        </main>
        <div className="hidden lg:block lg:w-[250px] lg:bg-layout-background lg:shrink-0 lg:min-h-screen">
          {/* Right aside */}
        </div>
      </div>

      <footer>
        {/* Footer content */}
        {/* <p>© 2024 Henna</p> */}
      </footer>
    </div>
  );
};

export default ChatLayout;
