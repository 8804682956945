import { useAuth } from "@clerk/clerk-react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { deleteCollection, fetchCollections } from "../../api/collectionAPI";
import CircularIndeterminate from "../CircularIndeterminate";

const CollectionList: React.FC = () => {
  const { getToken } = useAuth();
  const [currentCollectionId, setCurrentCollectionId] = useState<null | string>(
    null,
  );
  const queryClient = useQueryClient();

  const { isPending, error, data } = useQuery({
    queryKey: ["collections"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchCollections(freshToken);
      })(),
  });

  const mutation = useMutation({
    mutationFn: (collectionId: string) =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return deleteCollection(collectionId, freshToken);
      })(),
    onSuccess: (user) => {
      console.log("Message deletion successful", user);
      queryClient.invalidateQueries({
        queryKey: ["collections"],
      });
    },
    onError: (error: Error) => {
      console.error("Message error", error);
    },
  });
  const handleDeleteCollection = (e: React.FormEvent, collectionId: string) => {
    e.preventDefault();
    mutation.mutate(collectionId);
  };
  return (
    <div>
      <h2 className="text-lg">Groups of Values</h2>
      {isPending && <CircularIndeterminate />}
      {error && <p>An error has occurred: {error.message}</p>}
      <div className="flex flex-col gap-2">
        {data &&
          data?.map((collection, index) => (
            <div key={index}>
              <button
                onClick={() => setCurrentCollectionId(collection.id)}
                className="btn-henna m-2"
              >
                {collection.title}
              </button>
              {currentCollectionId === collection.id && (
                <div>
                  {collection.categories &&
                    collection.categories.length > 0 && (
                      <div>
                        {collection.categories.map((category, index2) => (
                          <div key={index2}>
                            <p className="font-bold">
                              {category.title}: ${category.description}
                            </p>
                            {category.values?.map((value, index) => (
                              <p key={index} className="ml-4">
                                {value.title}: {value.description}
                              </p>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              )}
              <button
                onClick={(e) => handleDeleteCollection(e, collection.id)}
                className="btn-henna m-2"
              >
                Delete Collection
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CollectionList;
