import axios, { AxiosError, AxiosResponse } from "axios";
import { User, UserMutationProps } from "../types/models/User";
interface UserInfo {
  email: string;
  password: string;
}

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function signUp(userInfo: UserInfo): Promise<User> {
  const response = await axios.post(`${backendURL}/user`, userInfo);
  if (response?.data?.user) {
    return response?.data?.user;
  } else {
    throw new Error("did not return a user");
  }
}
export async function signIn(userInfo: UserInfo): Promise<User> {
  const response = await axios.post(`${backendURL}/user/sign-in`, userInfo);
  if (response?.data?.user) {
    return response?.data?.user;
  } else {
    throw new Error("did not return a user");
  }
}

export async function fetchAllUsers(): Promise<User[]> {
  const response = await axios.get(`${backendURL}/user`);

  if (response?.data) {
    return response.data;
  } else {
    throw new Error("Failed to fetch users");
  }
}

export async function fetchCurrentUser(token: string | null): Promise<User> {
  if (!token) {
    throw new Error("token not provided");
  }
  const response = await axios.get(`${backendURL}/user/get-current-user`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data) {
    return response.data?.user;
  } else {
    throw new Error("Failed to fetch current user");
  }
}

export async function fetchUserById(id: string, token: string): Promise<User> {
  const response = await axios.get(`${backendURL}/user/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data?.user) {
    return response.data.user;
  } else {
    throw new Error("User not found");
  }
}

export async function updateUser(
  id?: string | null,
  updateData?: UserMutationProps,
  token?: string | null,
): Promise<User> {
  try {
    if (!id || !updateData || !token) {
      throw new Error("data was missing for this request");
    }
    const response = await axios.put(`${backendURL}/user/${id}`, updateData, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Failed to update the user");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function deleteUser(
  id: string,
  token: string,
): Promise<AxiosResponse> {
  if (!token) {
    throw new Error("token not provided");
  }
  if (!id) {
    throw new Error("id not provided");
  }
  const response = await axios.delete(`${backendURL}/user/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 204) {
    throw new Error("Failed to delete the user");
  }
  return response;
}
export async function createToken(
  token: string | null,
  organizationId: string,
  teamId: string,
): Promise<string> {
  if (!token) {
    throw new Error("token not provided");
  }
  const response = await axios.post(
    `${backendURL}/user/create-token`,
    { organizationId, teamId },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data) {
    return response.data?.url;
  } else {
    throw new Error("Failed to fetch current user");
  }
}
export async function JoinOrgAndTeamWithToken(
  token: string | null,
  jwtToken: string,
): Promise<User> {
  if (!token || !jwtToken) {
    throw new Error("One of the tokens was  not provided");
  }
  const response = await axios.post(
    `${backendURL}/user/verify-token`,
    { jwtToken: jwtToken },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data) {
    return response.data?.user;
  } else {
    throw new Error("Failed to fetch current user");
  }
}
export async function verifyPassword(password: string): Promise<boolean> {
  try {
    const response = await axios.post(`${backendURL}/user/verify-password`, {
      password: password,
    });
    if (response?.data?.isVerified === true) {
      return true;
    } else {
      throw new Error("did not return a user");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
