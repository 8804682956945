import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/layout/ChatLayout";
import HomePageLayout from "../components/layout/HomePageLayout";
const LeaderBoardPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  type ThemeMode = "light" | "dark" | "auto";

  return (
    <HomePageLayout>
      <section className="bg-henna-background">
        <div className="flex flex-col md:flex-row justify-between items-end">
          <div className="flex flex-col gap-6 ml-3 grow-0 md:max-w-[500px] ">
            <h1 className="text-5xl font-bold mt-2">
              Do LLMs treat different groups equitably?{" "}
              <span className="text-henna-yellow">{`quickly adopt AI`}</span>.
            </h1>
            <h3 className="text-2xl grow-0">
              We have tested leading models with standardized...
            </h3>
            <div className="flex items-center m-3"></div>
          </div>

          <img
            src="./henna-image-right-oriented.png"
            className="rounded-xl object-contain"
            style={{ height: "450px" }}
          />
        </div>
      </section>
      <section className="flex flex-col lg:flex-row gap-10 lg:gap-2 items-center justify-center lg:items-stretch justify-evenly my-12">
        <div className="bg-henna-background max-w-[500px] rounded-3xl p-5 flex flex-col items-center justify-center">
          <div className=" m-1 pl-2 flex flex-col md:flex-row gap-5 items-center border-l-4 border-henna-yellow">
            <ul className="flex flex-col gap-2">
              <ol className="text-2xl grow-0">
                {" "}
                Feeling pressure to adopt AI?
              </ol>
              <ol className="text-2xl grow-0">
                {" "}
                Worried it might be racist or sexist?
              </ol>
              <ol className="text-2xl grow-0"> We can help with that.</ol>
            </ul>
          </div>
        </div>
        <div className="max-w-[500px] bg-henna-background rounded-3xl p-5">
          <div className=" m-1 p-1 flex flex-col items-center gap-2">
            <h2 className="text-3xl grow-0">How It Works</h2>
            <ol className="list-decimal list-inside flex flex-col gap-3 text-lg">
              <li>
                <span>Tell us what you do with AI</span>
                <p className="ml-4 italic text-henna-brown">
                  What tools you use + how you use them
                </p>
              </li>
              <li>
                <span>Tell us who you work with</span>
                <p className="ml-4 italic text-henna-brown">
                  User occupations and demographics
                </p>
              </li>
              <li>
                <span>{`We'll run tests for fairness, inclusivity, and safety `}</span>
                <p className="ml-4 italic text-henna-brown">
                  So you can confidently pause or proceed
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-henna-background p-2 py-5">
        <div className="flex flex-col justify-between items-center gap-2">
          <h2 className="text-4xl font-bold text-henna-yellow">
            Models and Results
          </h2>
          <h2>Race:</h2>
          <table>
            <tr>
              <th>Model</th>
              <th>Score</th>
              <th>Meaning</th>
            </tr>
            <tr>
              <td>ChatGPT 4o</td>
              <td>xx</td>
              <td>LOW</td>
            </tr>
            <tr>
              <td>ChatGPT 4-turbo</td>
              <td>xx</td>
              <td>LOW</td>
            </tr>
            <tr>
              <td>ChatGPT 3.5-turbo</td>
              <td>Race</td>
              <td>xx</td>
              <td>LOW</td>
            </tr>
          </table>
        </div>
      </section>
      <br />
    </HomePageLayout>
  );
};

export default LeaderBoardPage;
