import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/ChatLayout";
import ChatWindow from "../../components/chat/ChatWindow";
import NewConversation from "../../components/chat/NewConversation";
import ConversationList from "../../components/chat/ConversationList";

// import SearchBar from "../components/SearchBar";
import { MyContext } from "../../context/Context";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";
import {
  ConversationInfo,
  createConversation,
  fetchConversations,
  updateConversation,
} from "../../api/conversationAPI";
import { Conversation } from "../../types/models/Conversation";
import { useSearchParams } from "react-router-dom";
interface AsideProps {
  currentOrganizationId: string | null;
  handleChangeConversationId: (conversationId: string) => void;
  conversationsData: any;
  conversationsIsPending: boolean;
  conversationsError: any;
  isGetFeedbackEnabled: boolean;
  isOrgValuesEnabled: boolean;
  handleUpdateOrCreateConversation: (
    e?: React.FormEvent,
    type?: string,
    action?: string,
  ) => void;
  mutation: any;
  currentConversationId?: string | undefined | null;
}

interface createConversationMutationProps {
  organizationId: string;
  type?: string;
  teamId: string;
  token?: string;
  stage?: string;
  id?: string;
}

// The Aside component
const Aside: React.FC<AsideProps> = ({
  currentOrganizationId,
  handleChangeConversationId,
  conversationsData,
  conversationsIsPending,
  conversationsError,
  handleUpdateOrCreateConversation,
  mutation,
  isGetFeedbackEnabled,
  currentConversationId,
  isOrgValuesEnabled,
}) => (
  <div className="flex flex-col">
    <span className="font-sans text-2xl font-bold ml-5">Messages</span>
    {currentOrganizationId && (
      <NewConversation
        organizationId={currentOrganizationId}
        setCurrentConversationId={handleChangeConversationId}
        conversationsData={conversationsData}
        mutation={mutation}
        isGetFeedbackEnabled={isGetFeedbackEnabled}
        handleUpdateOrCreateConversation={handleUpdateOrCreateConversation}
        isOrgValuesEnabled={isOrgValuesEnabled}
      />
    )}

    {currentOrganizationId && (
      <ConversationList
        setCurrentConversationId={handleChangeConversationId}
        conversationsData={conversationsData}
        conversationsIsPending={conversationsIsPending}
        conversationsError={conversationsError}
        currentConversationId={currentConversationId}
      />
    )}
    {/* <SearchBar width=" w-11/12 " /> */}
  </div>
);
const MessagesPage: React.FC = () => {
  const [currentConversationId, setCurrentConversationId] = useState("");
  const [isGetFeedbackEnabled, setIsGetFeedbackEnabled] = useState(false);
  const [isOrgValuesEnabled, setIsOrgValuesEnabled] = useState(false);
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const { user } = useContext(MyContext);
  const teamId = user?.selectedTeamId ?? "";
  const organizationId = user?.selectedOrganizationId ?? "";

  const [params] = useSearchParams();

  const conversationMutation = useMutation({
    mutationFn: (conversationInfo: createConversationMutationProps) =>
      (async () => {
        const fetchedToken = await getToken();
        if (!fetchedToken) {
          throw new Error("Your token could not be accessed");
        }
        conversationInfo.token = fetchedToken;
        if (conversationInfo.id && conversationInfo.stage) {
          return updateConversation(
            conversationInfo.organizationId,
            conversationInfo.teamId,
            conversationInfo.id,
            { stage: conversationInfo.stage },
            fetchedToken,
          );
        }
        return createConversation(conversationInfo as ConversationInfo);
      })(),
    onSuccess: (conversation) => {
      queryClient.invalidateQueries({ queryKey: ["conversation"] });
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
      setCurrentConversationId(conversation?.id);
    },

    onError: (error: Error) => {
      console.error("Conversation error", error);
    },
  });

  const {
    isPending: conversationsIsPending,
    error: conversationsError,
    data: conversationsData,
  } = useQuery({
    queryKey: ["conversations", organizationId, teamId],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchConversations(organizationId, teamId as string, freshToken);
      })(),
    enabled: !!teamId,
  });

  const handleUpdateOrCreateValuesConversation = (
    e?: React.FormEvent,
    type?: string,
    action?: string,
  ) => {
    e?.preventDefault();
    if (conversationMutation.isPending) {
      return;
    }
    if (!teamId) {
      throw new Error("TeamID is missing, could not create a new conversation");
    }
    let found = false;
    if (conversationsData && type === "discussValues") {
      const conversation = conversationsData.find(
        (conversation: Conversation) => conversation.type === "DISCUSSVALUES",
      );
      if (conversation) {
        setCurrentConversationId(conversation.id);
        found = true;
      }
    }
    if (found === false && type) {
      // this creates a conversation
      conversationMutation.mutate({ organizationId, teamId, type });
    }
    // update an existing conversation:
    if (action === "update" && currentConversationId) {
      conversationMutation.mutate({
        id: currentConversationId,
        stage: "DISCUSSVALUESGROUP",
        organizationId,
        teamId,
      });
    }
  };
  useEffect(() => {
    if (!conversationsData) {
      return;
    }
    for (const conversation of conversationsData) {
      if (conversation.stage === "DISCUSSVALUESGROUP") {
        setIsGetFeedbackEnabled(true);
      }
    }
  }, [conversationsData]);
  useEffect(() => {
    if (currentConversationId) {
      return;
    }
    const selectedType = params.get("selected-conversation-type");
    if (selectedType === "discussValues" && teamId && conversationsData) {
      handleUpdateOrCreateValuesConversation(undefined, "discussValues");
    }
  }, [params, teamId, conversationsData]);
  useEffect(() => {
    if (currentConversationId) {
      const conversation = conversationsData?.find(
        (conversation) => conversation.id === currentConversationId,
      );
      if (conversation?.type === "FEEDBACK") {
        setIsOrgValuesEnabled(false);
      } else {
        setIsOrgValuesEnabled(true);
      }
    }
  }, [currentConversationId, conversationsData]);
  const renderAside = () => (
    <Aside
      currentOrganizationId={user?.selectedOrganizationId ?? null}
      handleChangeConversationId={setCurrentConversationId}
      conversationsIsPending={conversationsIsPending}
      conversationsError={conversationsError}
      conversationsData={conversationsData}
      mutation={conversationMutation}
      handleUpdateOrCreateConversation={handleUpdateOrCreateValuesConversation}
      isGetFeedbackEnabled={isGetFeedbackEnabled}
      currentConversationId={currentConversationId}
      isOrgValuesEnabled={isOrgValuesEnabled}
    />
  );
  return (
    <Layout aside={renderAside}>
      {/* <NewOrganization /> */}

      {(!user?.selectedOrganizationId || !user?.selectedTeamId) && (
        <p>
          You must join a team and organization in order to chat with Henna.
        </p>
      )}
      {currentConversationId &&
        user?.selectedOrganizationId &&
        user?.selectedTeamId && (
          <ChatWindow conversationId={currentConversationId} />
        )}
    </Layout>
  );
};

export default MessagesPage;
