import React, { useState, useEffect, useContext } from "react";
import hennaDesktopLogo from "../components/logos/Henna-Background-desktop.png";
import { SignIn } from "@clerk/clerk-react";
import { SignUp } from "@clerk/clerk-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WelcomeMessage from "../components/chat/WelcomeMessage";
import { MyContext } from "../context/Context";
import EnterPasswordForm from "../components/user/EnterPasswordForm";
type newParamsType = {
  "sign-in"?: string;
  "sign-up"?: string;
  token?: string;
};
type LoginFormDesktopParams = {
  setParams: (newParams: newParamsType) => void;
  jwtToken: string | null;
  includeWelcomeMessage?: boolean;
};
const LoginFormDesktop: React.FC<LoginFormDesktopParams> = ({
  setParams,
  jwtToken,
  includeWelcomeMessage,
}) => {
  const handleSubmitSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (jwtToken) {
      setParams({ "sign-in": "true", token: jwtToken });
    } else {
      setParams({ "sign-in": "true" });
    }
  };
  const handleSubmitSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (jwtToken) {
      setParams({ "sign-up": "true", token: jwtToken });
    } else {
      setParams({ "sign-up": "true" });
    }
  };

  return (
    <div className="p-10 flex flex-col justify-center items-center">
      {includeWelcomeMessage && <WelcomeMessage />}
      <form
        className="flex flex-col items-center space-y-4"
        onSubmit={handleSubmitSignIn}
      >
        <button
          className="App-rounded-button henna-green"
          // Correctly use setParams
        >
          Login
        </button>
      </form>
      <form
        className="flex flex-col items-center space-y-4"
        onSubmit={handleSubmitSignUp}
      >
        <button className="App-rounded-button mb-0">Sign Up</button>
      </form>
    </div>
  );
};
const AuthClerkPage: React.FC = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [params, setParams] = useSearchParams();
  const [showSignIn, setShowSignIn] = useState(false);
  const [jwtToken, setJwtToken] = useState<null | string>("");
  const [knowsPassword, setKnowsPassword] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { user } = useContext(MyContext);
  if (user) {
    navigate("/chat/dashboard");
  }
  useEffect(() => {
    const signIn = params.get("sign-in") === "true";
    const signUp = params.get("sign-up") === "true";
    const jt = params.get("token");
    setJwtToken(jt);
    setShowSignIn(signIn);
    setShowSignUp(signUp);
  }, [params]);

  return (
    <div>
      <div className="flex font-sans overflow-hidden lg:justify-end lg:h-screen lg:size-full">
        <div className="hidden lg:block lg:flex-grow lg:min-h-full lg:size-full">
          <img
            src={hennaDesktopLogo}
            className="object-cover object-right size-full"
            alt="Henna's premier desktop logo"
          />
        </div>
        <div className="flex flex-col  items-center w-full lg:w-[450px]">
          <div className="w-full flex flex-row justify-end">
            <Link to="/" className="helper-text m-4 lg:mb:6">
              Back to Landing Page
            </Link>
          </div>

          {!knowsPassword && (
            <EnterPasswordForm
              password={password}
              setPassword={setPassword}
              setKnowsPassword={setKnowsPassword}
            />
          )}

          {knowsPassword && (
            <div className="flex flex-col justify-center items-center">
              <WelcomeMessage className="m-4" />
              <div className="flex justify-center mb-4 lg:hidden">
                <img
                  src="./Henna-Background-mobile.png"
                  alt="Henna's premier mobile logo"
                />
              </div>
              {showSignIn && (
                <SignIn
                  signUpUrl={`/auth?sign-up=true${jwtToken ? `&token=${jwtToken}` : ""}`}
                  redirectUrl={`${jwtToken ? `/chat/join?token=${jwtToken}` : "/chat/dashboard"}`}
                />
              )}
              {showSignUp && (
                <SignUp
                  signInUrl={`/auth?sign-in=true${jwtToken ? `&token=${jwtToken}` : ""}`}
                  redirectUrl={`${jwtToken ? `/chat/join?token=${jwtToken}` : "/chat/dashboard"}`}
                />
              )}
              {!showSignUp && !showSignIn && (
                <LoginFormDesktop setParams={setParams} jwtToken={jwtToken} />
              )}
              <div className="flex flex-col items-center m-4 helper-text gap-2">
                <Link to="/terms">Terms And Conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthClerkPage;
