import React from "react";

interface InputBarProps {
  setValue: (value: string) => void;
  value: string;
  type: string;
  placeholder: string;
  className?: string;
  id?: string;
}

export const InputBar: React.FC<InputBarProps> = ({
  setValue,
  value,
  type,
  placeholder,
  className = "border-b border-gray-300 py-2 px-3 placeholder-gray-400 text-gray-700 focus:outline-none focus:ring focus:border-blue-500 mb-6",
  id,
}) => (
  <input
    id={id}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    className={className}
  />
);
