import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { fetchTeams } from "../../api/teamAPI";
import CircularIndeterminate from "../CircularIndeterminate";

import { Team } from "../../types/models/Team";

interface TeamListProps {
  organizationId: string;
  handleClickTeam: (team: Team) => void;
  isPending: boolean;
}
const TeamList: React.FC<TeamListProps> = ({
  organizationId,
  handleClickTeam,
  isPending: clickTeamIsPending,
}) => {
  const { getToken } = useAuth();

  const {
    data: dataTeams,
    isPending,
    error,
  } = useQuery({
    queryKey: ["teamsAside"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken || !organizationId) {
          throw new Error("could not generate a token for this request");
        }
        return fetchTeams(organizationId, freshToken);
      })(),

    enabled: !!organizationId,
  });

  return (
    <div>
      <h2 className="text-lg">Team List</h2>
      {isPending && <CircularIndeterminate />}
      {error && <p>An error has occurred: {error.message}</p>}
      <div className="flex flex-col gap-2">
        {dataTeams &&
          dataTeams.map((team, index2) => (
            <div key={index2}>
              {" "}
              <button
                onClick={() => handleClickTeam(team)}
                disabled={clickTeamIsPending}
              >
                {team.title}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TeamList;
