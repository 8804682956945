import React from "react";
import classNames from "classnames";

interface WelcomeMessageProps {
  className?: string;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ className }) => {
  const WelcomeMessageClass: string = classNames(
    className,
    "text-center w-full",
  );

  return (
    <div
      className={WelcomeMessageClass}
      style={{ width: "314.8px", fontFamily: "Lexend" }}
    >
      <div
        className="text-xl font-semibold leading-8 tracking-tight"
        style={{
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "27px",
          letterSpacing: "0em",
        }}
      >
        Welcome
      </div>
      <div className="helper-text flex flex-col">
        <span>Welcome! Please enter your details</span>
      </div>
    </div>
  );
};

export default WelcomeMessage;
