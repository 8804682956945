import React, { RefObject } from "react";
import MessageBubble from "./FormattedMessage";
import MessageSenderHeader from "./MessageSenderHeader";
interface MessageBubbleProps {
  text: string;
  sender: string;
  initials: string;
  messageRef: RefObject<HTMLDivElement>;
}
const MessageAndSenderWrapper: React.FC<MessageBubbleProps> = ({
  text,
  sender,
  initials,
  messageRef,
}) => {
  return (
    <div className="font-sans text-2xl my-10">
      <MessageSenderHeader sender={sender} initials={initials} />
      <MessageBubble message={text} sender={sender} messageRef={messageRef} />
    </div>
  );
};

export default MessageAndSenderWrapper;
