import React, { useRef, useState } from "react";

import HomePageLayout from "../components/layout/HomePageLayout";
const PrivacyPolicyPage: React.FC = () => {
  return (
    <HomePageLayout>
      <section className="bg-henna-background py-10 flex flex-col gap-6 items-center ">
        <iframe
          src="https://www.freeprivacypolicy.com/live/28b7d665-6001-4f52-aa74-95547333b14b"
          className="w-3/4 rounded h-75vh"
        />
        {/* <h1 className="header-henna-1">Privacy Policy for Henna</h1>
          <h1 className="header-henna-2">Privacy Policy</h1>
          <p>Last updated: April 13, 2024</p>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Free Privacy Policy
            Generator.
          </p>
          <h1 className="header-henna-2">Interpretation and Definitions</h1>
          <h1 className="header-henna-3">Interpretation</h1>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p> */}
      </section>
    </HomePageLayout>
  );
};

export default PrivacyPolicyPage;
