// MessageInputForm.tsx
import React from "react";
import { ReactComponent as SendMessageIcon } from "../logos/SendMessageButton.svg";
import { ReactComponent as SendMessageIconDisabled } from "../logos/SendMessageButtonDisabled.svg";
import { ReactComponent as AttachmentIcon } from "../logos/AttachmentIcon.svg";

interface MessageInputFormProps {
  newMessage: string;
  setNewMessage: (message: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
  errorMessage: string;
  successMessage: string;
  isPending: boolean;
  inputWidth: string;
}

const MessageInputForm: React.FC<MessageInputFormProps> = ({
  newMessage,
  setNewMessage,
  handleSubmit,
  isPending,
  inputWidth,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !isPending) {
      // Check if Enter key is pressed without Shift
      e.preventDefault(); // Prevent the default action to avoid a new line in textarea
      handleSubmit(e); // Submit the form
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className=" fixed flex items-center md:justify-between  h-24 py-1  my-1 rounded-lg bg-white border  bottom-0 left-1/2 transform -translate-x-1/2 px-2 md:px-4"
      style={{ width: inputWidth }}
    >
      {/* <AttachmentIcon /> */}
      <textarea
        id="new-message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        className=" flex-grow bg-transparent border-none outline-none font-sans text-sm focus:ring-0 resize-none"
        placeholder="Typing..."
        rows={4}
      />
      <button type="submit" disabled={isPending}>
        {isPending && <SendMessageIconDisabled />}
        {!isPending && <SendMessageIcon />}
      </button>
    </form>
  );
};

export default MessageInputForm;
