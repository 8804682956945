import axios, { AxiosError } from "axios";
import { Organization } from "../types/models/Organization";

const backendURL =
  process.env.REACT_APP_BACKEND_URL ?? "http://localhost:5000/api";

export async function createOrganization(
  title: string,
  token: string,
): Promise<Organization> {
  try {
    const response = await axios.post(
      `${backendURL}/organization`,
      { title },
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.organization) {
      return response.data.organization;
    } else {
      throw new Error("did not return a organization");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchOrganizations(
  token: string | null,
): Promise<Organization[]> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  try {
    const response = await axios.get(`${backendURL}/organization`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.organizations) {
      return response?.data?.organizations;
    } else {
      throw new Error("did not return a organization list");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}
export async function fetchOrganizationById(
  id: string,
  token: string,
): Promise<Organization> {
  const response = await axios.get(`${backendURL}/organization/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data?.organization) {
    return response?.data?.organization as Organization;
  } else {
    throw new Error("did not return a organization");
  }
}

export async function updateOrganization(
  id: string,
  updateData: { title?: string },
  token: string,
): Promise<Organization> {
  const response = await axios.put(
    `${backendURL}/organization/${id}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.organization) {
    return response.data.organization;
  } else {
    throw new Error("Failed to update the organization");
  }
}

export async function deleteOrganization(
  id: string,
  token: string,
): Promise<void> {
  const response = await axios.delete(`${backendURL}/organization/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to delete the organization");
  }
}
