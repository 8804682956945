import axios, { AxiosError } from "axios";
import { JoinRequest } from "../types/models/JoinRequest"; // Ensure you have defined this type

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createJoinRequest(
  token: string,
  organizationTitle: string,
  teamTitle: string,
  message?: string,
): Promise<JoinRequest> {
  try {
    const response = await axios.post(
      `${backendURL}/user/join-request`,
      { organizationTitle, teamTitle, message },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.joinRequest) {
      return response.data.joinRequest;
    } else {
      throw new Error("Did not return a join request");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function fetchMyJoinRequests(
  token: string,
): Promise<JoinRequest[]> {
  try {
    const response = await axios.get(`${backendURL}/user/join-request`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.joinRequests) {
      return response.data.joinRequests;
    } else {
      throw new Error("Did not return a list of join requests");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function fetchJoinRequestById(
  id: string,
  token: string,
): Promise<JoinRequest> {
  try {
    const response = await axios.get(`${backendURL}/user/join-request/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.joinRequest) {
      return response.data.joinRequest;
    } else {
      throw new Error("Did not return a join request");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function fetchOrganizationJoinRequests(
  token: string,
): Promise<JoinRequest[]> {
  try {
    const response = await axios.get(
      `${backendURL}/user/join-request/by-organization`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.joinRequests) {
      return response.data.joinRequests;
    } else {
      throw new Error("Did not return a list of join requests");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function updateJoinRequest(
  token: string,
  id: string,
  updateData: { newStatus: string },
): Promise<JoinRequest> {
  try {
    const response = await axios.put(
      `${backendURL}/user/join-request/${id}`,
      updateData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.joinRequest) {
      return response.data.joinRequest;
    } else {
      throw new Error("Failed to update the join request");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function deleteJoinRequest(
  id: string,
  token: string,
): Promise<void> {
  try {
    const response = await axios.delete(
      `${backendURL}/user/join-request/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status !== 200) {
      throw new Error("Failed to delete the join request");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}
