import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/layout/ChatLayout";
import HomePageLayout from "../components/layout/HomePageLayout";
const HomePage: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  type ThemeMode = "light" | "dark" | "auto";

  return (
    <HomePageLayout>
      <section className="bg-henna-background">
        <div className="flex flex-col md:flex-row justify-between items-end md:items-center">
          <div className="flex flex-col gap-6 ml-3 grow-0 md:max-w-[500px] items-center h-full p-2">
            <h1 className="text-5xl font-bold mt-2">
              The safe way to{" "}
              <span className="text-henna-yellow">{`quickly adopt AI`}</span>.
            </h1>
            <h3 className="text-2xl grow-0">
              {" "}
              Run tailored audits for fairness, inclusivity, and safety
            </h3>
            <div className="flex items-center m-3">
              {/* <Link to="/leaderboard" className="btn-henna mb-4">
                <span>View Our Leaderboard</span>
              </Link> */}
            </div>
          </div>

          <img
            src="./henna-image-right-oriented.png"
            className="rounded-xl object-contain"
            style={{ height: "450px" }}
          />
        </div>
      </section>
      <section className="flex flex-col lg:flex-row gap-10 lg:gap-2 items-center justify-center lg:items-stretch justify-evenly">
        <div className="bg-henna-background max-w-[500px] rounded-3xl p-5 flex flex-col items-center justify-center">
          <div className=" m-1 pl-2 flex flex-col md:flex-row gap-5 items-center border-l-4 border-henna-yellow">
            <ul className="flex flex-col gap-2">
              <ol className="text-2xl grow-0">
                {" "}
                Feeling pressure to adopt AI?
              </ol>
              <ol className="text-2xl grow-0">
                {" "}
                Worried it might be racist or sexist?
              </ol>
              <ol className="text-2xl grow-0"> We can help with that.</ol>
            </ul>
          </div>
        </div>
        <div className="max-w-[500px] bg-henna-background rounded-3xl p-5">
          <div className=" m-1 p-1 flex flex-col items-center gap-2">
            <h2 className="text-3xl grow-0">How It Works</h2>
            <ol className="list-decimal list-inside flex flex-col gap-3 text-lg">
              <li>
                <span>Tell us what you do with AI</span>
                <p className="ml-4 italic text-henna-brown">
                  What tools you use + how you use them
                </p>
              </li>
              <li>
                <span>Tell us who you work with</span>
                <p className="ml-4 italic text-henna-brown">
                  User occupations and demographics
                </p>
              </li>
              <li>
                <span>{`We'll run tests for fairness, inclusivity, and safety `}</span>
                <p className="ml-4 italic text-henna-brown">
                  So you can confidently pause or proceed
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-henna-background p-2 py-5">
        <div className="flex flex-col justify-between items-center gap-2">
          <h2 className="text-4xl font-bold text-henna-yellow">
            Proudly Supported By
          </h2>
          <div className="flex flex-row gap-2">
            <div className="flex align-center justify-center">
              <img
                src="/venture-crush-cropped.png"
                className="grow-0 w-52 h-auto"
              />
            </div>
            <div className="flex align-center justify-center">
              <img src="/osu-primarylogo.png" className="grow-0 w-52 h-auto" />
            </div>
            <div className="flex align-center justify-center">
              <img
                src="/Portland-State-University-logo-cropped.png"
                className="grow-0 w-52 h-auto"
              />
            </div>
            <div className="flex align-center justify-center">
              <img src="/RAIN-LOGO.png" className="grow-0 w-52 h-auto" />
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row gap-10 lg:gap-2 items-center lg:items-stretch justify-evenly">
        <div className="bg-henna-background max-w-[500px] rounded-3xl p-5">
          <div className=" m-1 p-1 flex flex-col md:flex-row gap-5 items-center">
            <img
              className="rounded-2xl grow-0"
              src="/arsh-headshot.jpeg"
              style={{ height: `200px`, width: "200px" }}
            />
            <h2 className="text-3xl max-w-[500px]">
              Founded by {` `}
              <Link
                className="text-henna-yellow font-bold"
                to="https://www.linkedin.com/in/arshhaque"
              >
                Arsh Haque
              </Link>
              {`, a queer, neuroatypical person of color & DEI professional.`}
            </h2>
          </div>
        </div>
      </section>
      <section className="bg-henna-background pt-2 mb-10">
        <div className="flex flex-col md:flex-row justify-between items-end ml-3">
          <div className="flex flex-col gap-6 ml-3 grow-0 md:max-w-[500px] ">
            <h1 className="text-4xl font-bold">
              {`Also check out the Henna chatbot—it's an `}
              <span className="text-henna-yellow">AI Assistant</span>{" "}
              <span>that helps teams with</span>{" "}
              <span className="text-henna-yellow">
                {`Diversity, Equity, & Inclusion`}
              </span>
              .
            </h1>
            <h3 className="text-2xl grow-0">
              {" "}
              The Henna chatbot helps teams conduct listening tours, craft team
              values, and align their actions to those values.
            </h3>
            <div className="flex items-center m-3">
              <Link
                to="https://drive.google.com/file/d/1wHJYO_2WLzggWWb3q5j1HGij7aGc87VF/view"
                className="btn-henna"
              >
                <span>Learn More</span>
              </Link>
            </div>
          </div>

          <img
            src="./henna-image-right-oriented.png"
            className="rounded-xl object-contain"
            style={{ height: "450px" }}
          />
        </div>
      </section>
    </HomePageLayout>
  );
};

export default HomePage;
