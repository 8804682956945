import React, { Ref, RefObject, useRef } from "react";
interface MessageBubbleProps {
  message: string;
  sender: string;
  messageRef: RefObject<HTMLDivElement>;
}
const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  sender,
  messageRef,
}) => {
  return (
    <div
      className={` mx-10 rounded-b-lg bg-white ${sender === "You" ? "rounded-l-lg" : "rounded-r-lg"}`}
      ref={messageRef}
    >
      <div
        className="font-sans text-sm text-black  p-5 flex flex-col gap-3 break-words"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

export default MessageBubble;
