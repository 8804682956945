import React, { useRef, useState } from "react";

import HomePageLayout from "../components/layout/HomePageLayout";
const TermsPage: React.FC = () => {
  return (
    <HomePageLayout>
      <section className="bg-henna-background py-10 flex flex-col gap-6 items-center ">
        <h1 className="header-henna-1">Terms And Conditions</h1>
        <iframe
          src="/henna-pilot-agreement.pdf"
          className="w-4/5 rounded h-75vh"
        />
      </section>
    </HomePageLayout>
  );
};

export default TermsPage;
