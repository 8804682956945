import React, { RefObject, useContext } from "react";
import { Message } from "../../types/models/Message";
import { MyContext } from "../../context/Context";
import MessageAndSenderWrapper from "./MessageAndSenderWrapper";
import { marked } from "marked";
import DOMPurify from "dompurify";
interface MessageListProps {
  data: any;
  latestResponse: string;
  messageRef: RefObject<HTMLDivElement>;
}
const MessageList: React.FC<MessageListProps> = ({
  data,
  latestResponse,
  messageRef,
}) => {
  const { user } = useContext(MyContext);
  const firstInitial = user?.firstName?.[0] ?? "";
  const lastInitial = user?.lastName?.[0] ?? "";
  const initials = firstInitial + lastInitial;
  const renderer = {
    code(code: string, infostring: string | undefined, escaped: boolean) {
      // Don't wrap the code in any HTML tags, just return the content
      return code;
    },
  };
  marked.use({ renderer });

  const sanitizeMarkdown = (markdown: string | null | undefined) => {
    if (!markdown) {
      return "";
    }
    const rawHtml = marked.parse(markdown) as string;
    if (!rawHtml) {
      return "";
    }

    const cleanHtml = DOMPurify.sanitize(rawHtml); // Sanitize HTML
    return cleanHtml;
  };
  return (
    data && (
      <div>
        {data?.messages?.map((message: Message, index: React.Key) => (
          <div key={index}>
            <MessageAndSenderWrapper
              sender={`${message.user ? "You" : "Henna"}`}
              text={sanitizeMarkdown(message?.text) ?? ""}
              initials={initials}
              messageRef={messageRef}
            />
          </div>
        ))}
        {/* <div>
          {sentMessage && (
            <MessageAndSenderWrapper
              sender={"You"}
              initials={initials}
              text={sentMessage}
            />
          )}
        </div> */}
        <div>
          {latestResponse && (
            <MessageAndSenderWrapper
              sender={"Henna"}
              initials={initials}
              text={latestResponse}
              messageRef={messageRef}
            />
          )}
        </div>
      </div>
    )
  );
};

export default MessageList;
