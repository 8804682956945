import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
// import SearchBar from "../SearchBar";
import AccountSection from "../AccountSection";
import Divider from "../Divider";
import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import menuSearchButton from "../logos/message-search.png";
import { Navbar } from "flowbite-react";
import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
// import conversationSearchButton from "../logos/messages-2.svg";
// import groupOfPeopleIcon from "../logos/people.svg";
interface LayoutProps {
  children: React.ReactNode;
  aside?: () => JSX.Element;
}

const HomePageLayout: React.FC<LayoutProps> = ({ children, aside }) => {
  const { isSignedIn } = useUser();

  return (
    <div className="min-h-screen flex flex-col ">
      <Navbar fluid rounded>
        <Navbar.Brand as={Link} to="/">
          <img src="./henna.png" className="mr-3 h-6 sm:h-9" alt="Henna" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="">
          {isSignedIn && (
            <Navbar.Link href="/chat/dashboard" className="mt-2">
              Dashboard
            </Navbar.Link>
          )}
          {!isSignedIn && (
            <Navbar.Link href="/auth?sign-up=true" className="mt-2">
              Sign Up
            </Navbar.Link>
          )}
          {!isSignedIn && (
            <Navbar.Link href="/auth?sign-in=true" className="mt-2">
              Log In
            </Navbar.Link>
          )}

          <Link to="https://calendly.com/arshhaque/call" className="btn-henna">
            <span>Schedule a Call</span>
          </Link>
        </Navbar.Collapse>
      </Navbar>
      <main className="flex flex-col flex-grow gap-10">{children}</main>
      <footer className="bg-henna-background flex flex-col justify-center items-center py-12 gap-3">
        <Link to="/">
          <img src="/henna-no-bg.png" width="100px" />
        </Link>
        <Link to="/terms" className="helper-text">
          <span>Terms and Conditions</span>
        </Link>
        <Link to="/privacy-policy" className="helper-text">
          <span>Privacy Policy</span>
        </Link>
      </footer>
    </div>
  );
};

export default HomePageLayout;
