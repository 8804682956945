import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTeamById, generateTeamValueSummary } from "../../api/teamAPI";
import { Team } from "../../types/models/Team";
interface TeamListProps {
  organizationId: string;
  teamId: string;
}
interface TeamMutationProps {
  organizationId: string;
  teamId: string;
}
const TeamDashboard: React.FC<TeamListProps> = ({ organizationId, teamId }) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const { data: team } = useQuery<Team, Error>({
    queryKey: ["team", teamId],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken(); // Retrieve fresh token asynchronously
        if (!freshToken) {
          throw new Error("Your authentication token could not be accessed");
        }
        return fetchTeamById(organizationId, teamId, freshToken);
      })(),
  });
  const mutation = useMutation({
    mutationFn: (props: TeamMutationProps) =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return generateTeamValueSummary(
          props.organizationId,
          props.teamId,
          freshToken,
        );
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["collections"] });
      queryClient.invalidateQueries({
        queryKey: ["team"],
      });
    },
    onError: (error: Error) => {
      console.error("team error", error);
    },
  });
  const handleConsolidateValues = () => {
    mutation.mutate({ teamId, organizationId });
  };
  return (
    <div>
      <h2 className="text-lg">{team?.title} Values Dashboard</h2>

      <table className="my-3">
        <th>Team Members: </th>
        <th>Have they started a conversation with Henna?</th>
        <th>Have they moved on to discuss team values?</th>
        {team?.users?.map((user, index) => (
          <tr key={index} className="">
            <td className="border border-black">
              <span>
                Name: {user?.firstName} {user?.lastName}
              </span>
              <br />
              <span>Email: {user?.email}</span>
            </td>
            <td className="border border-black">
              {user?.conversations?.length && user?.conversations?.length > 0
                ? "YES"
                : "NO"}
            </td>
            <td className="border border-black">
              {user?.conversations?.length &&
              user?.conversations.find(
                (conversation) => conversation.stage === "DISCUSSVALUESGROUP",
              )
                ? "YES"
                : "NO"}
            </td>
          </tr>
        ))}
      </table>
      <button onClick={handleConsolidateValues} className="btn-henna">
        Consolidate Values and create Report
      </button>
      {mutation.isPending && (
        <p>We are generating your report... This may take a minute...</p>
      )}
      <div style={{ whiteSpace: "pre-wrap" }}>{team?.valueSummary}</div>
    </div>
  );
};

export default TeamDashboard;
