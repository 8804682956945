import axios from "axios";
import { Collection } from "../types/models/Collection";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createCollection(
  title: string,
  token: string,
): Promise<Collection> {
  const response = await axios.post(
    `${backendURL}/collection`,
    { title },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.collection) {
    return response?.data?.collection;
  } else {
    throw new Error("did not return a collection");
  }
}
export async function fetchCollections(
  token: string | null,
): Promise<Collection[]> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.get(`${backendURL}/collection`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data?.collections) {
    return response?.data?.collections;
  } else {
    throw new Error("did not return a collection list");
  }
}
export async function fetchCollectionById(
  id: string,
  token: string,
): Promise<Collection> {
  const response = await axios.get(`${backendURL}/collection/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data?.collection) {
    return response?.data?.collection as Collection;
  } else {
    throw new Error("did not return a collection");
  }
}

export async function updateCollection(
  id: string,
  updateData: { title?: string },
  token: string,
): Promise<Collection> {
  const response = await axios.put(
    `${backendURL}/collection/${id}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.collection) {
    return response.data.collection;
  } else {
    throw new Error("Failed to update the collection");
  }
}

export async function deleteCollection(
  id: string,
  token: string,
): Promise<void> {
  const response = await axios.delete(`${backendURL}/collection/${id}`, {
    headers: {
      "Content-Type": "application/json", // Example header
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to delete the collection");
  }
}
