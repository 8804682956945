import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MessagesPage from "./pages/chat/MessagesPage";
import NotFoundPage from "./pages/NotFoundPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthClerkPage from "./pages/AuthClerkPage";
import AdminPage from "./pages/chat/AdminPage";
import { ProtectedRouteIsLoggedIn } from "./components/protectedRotes/ProtectedRouteIsLoggedIn";
import ContextProvider from "./context/ContextProvider";
import TeamPage from "./pages/chat/TeamPage";
import { ProtectedRouteIsTeamLeader } from "./components/protectedRotes/ProtectedRouteIsTeamLeader";
import { ProtectedRouteIsAdmin } from "./components/protectedRotes/ProtectedRouteIsAdmin";
import JoinOrgAndTeamPage from "./pages/chat/JoinOrgAndTeamPage";
import AccountPage from "./pages/chat/AccountPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsPage from "./pages/TermsPage";
import DashboardPage from "./pages/chat/DashboardPage";
import HomePage from "./pages/HomePage";
import LeaderBoardPage from "./pages/LeaderboardPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: (attemptIndex) => Math.min(500 * 2 ** attemptIndex, 10000),
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/leaderboard" element={<LeaderBoardPage />} />
            <Route
              path="/chat/dashboard"
              element={
                <ProtectedRouteIsLoggedIn>
                  <DashboardPage />
                </ProtectedRouteIsLoggedIn>
              }
            />
            <Route path="/chat/home" element={<HomePage />} />
            <Route
              path="/chat/admin"
              element={
                <ProtectedRouteIsAdmin>
                  <AdminPage />
                </ProtectedRouteIsAdmin>
              }
            />
            <Route
              path="/chat/messages"
              element={
                <ProtectedRouteIsLoggedIn>
                  <MessagesPage />
                </ProtectedRouteIsLoggedIn>
              }
            />
            <Route
              path="/chat/team"
              element={
                <ProtectedRouteIsTeamLeader>
                  <TeamPage />
                </ProtectedRouteIsTeamLeader>
              }
            />
            {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
            <Route path="/auth" element={<AuthClerkPage />} />
            <Route
              path="/chat/join"
              element={
                <ProtectedRouteIsLoggedIn>
                  <JoinOrgAndTeamPage />
                </ProtectedRouteIsLoggedIn>
              }
            />
            <Route
              path="/chat/account"
              element={
                <ProtectedRouteIsLoggedIn>
                  <AccountPage />
                </ProtectedRouteIsLoggedIn>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default App;
