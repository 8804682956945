// ThemeProvider.js
import { useAuth, useUser } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { ReactNode } from "react";
import { fetchCurrentUser, updateUser } from "../api/userAPI";
import { User, UserMutationProps } from "../types/models/User";
import { MyContext } from "./Context";
interface ContextProviderProps {
  children: ReactNode; // This type accepts any valid React child
}
interface UserUpdateProps {
  userFields: UserMutationProps;
}

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const { user: clerkUser } = useUser();
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const { data: user } = useQuery<User, Error>({
    queryKey: ["userContext", clerkUser?.id],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken(); // Retrieve fresh token asynchronously
        return fetchCurrentUser(freshToken);
      })(),
    enabled: !!clerkUser?.id, // Enable the query only if clerkUser.id is available
  });

  const userMutation = useMutation({
    mutationFn: (userUpdateProps: UserUpdateProps) =>
      (async () => {
        const freshToken = await getToken(); // Retrieve fresh token asynchronously
        if (!freshToken) {
          throw new AxiosError(
            "Your authentication token could not be accessed",
          );
        }
        return updateUser(user?.id, userUpdateProps.userFields, freshToken);
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userContext"] });
    },
    onError: (error: Error) => {
      console.error("User update error", error);
    },
  });
  return (
    <MyContext.Provider value={{ user, userMutation }}>
      {children}
    </MyContext.Provider>
  );
};

export default ContextProvider;
