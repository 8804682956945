import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createJoinRequest } from "../../api/joinRequestAPI";
import ButtonOutline from "../../components/ButtonOutline";
import Layout from "../../components/layout/ChatLayout";
import JoinRequestForm from "../../components/chat/JoinRequestForm";
import WelcomeBanner from "../../components/chat/WelcomeBanner";
import { MyContext } from "../../context/Context";
import { Team } from "../../types/models/Team";
import CircularIndeterminate from "../../components/CircularIndeterminate";

const DashboardPage: React.FC = () => {
  const { user, userMutation } = useContext(MyContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { getToken } = useAuth();

  useEffect(() => {
    if (user !== undefined) {
      setIsLoading(false);
    }
  }, [user]);

  const handleTalkAboutValues = () => {
    navigate("/chat/messages?selected-conversation-type=discussValues");
  };
  const handleMangeTeams = () => {
    navigate("/chat/team");
  };
  let Aside: React.FC = () => (
    <div className="flex flex-col">
      <span className="text-xl font-bold">{"Let's Chat"}</span>
      {/* <span className="text-henna-green">Update Henna</span> */}
      <div>
        <ButtonOutline
          text="Chat With Henna"
          handleClick={handleTalkAboutValues}
        ></ButtonOutline>
      </div>
    </div>
  );
  if (
    ["TEAMLEADER", "ORGANIZATIONLEADER", "ADMIN", "GLOBALADMIN"].includes(
      user?.role?.title ?? "",
    )
  ) {
    const AdminAside: React.FC = () => (
      <div className="flex flex-col gap-2">
        <span className="text-xl font-bold">{"Let's Chat"}</span>
        {/* <span className="text-henna-green">Update Henna</span> */}
        <div>
          <ButtonOutline
            text="Chat with Henna"
            handleClick={handleTalkAboutValues}
          ></ButtonOutline>
        </div>
        <div>
          <ButtonOutline
            text="Manage Teams"
            handleClick={handleMangeTeams}
          ></ButtonOutline>
        </div>{" "}
      </div>
    );
    AdminAside.displayName = "AdminAside";
    Aside = AdminAside;
  } else if (
    ["TEAMLEADER", "ORGANIZATIONLEADER"].includes(user?.role?.title ?? "")
  ) {
    const AdminAside: React.FC = () => (
      <div className="flex flex-col">
        <span className="text-xl font-bold">{"Let's Chat"}</span>
        {/* <span className="text-henna-green">Update Henna</span> */}
        <div>
          <ButtonOutline
            text="Chat With Henna"
            handleClick={handleTalkAboutValues}
          ></ButtonOutline>
        </div>
        <div>
          <ButtonOutline
            text="Manage Teams"
            handleClick={handleMangeTeams}
          ></ButtonOutline>
        </div>
      </div>
    );
    AdminAside.displayName = "AdminAside";
    Aside = AdminAside;
  }
  const renderAside = () => <Aside />;

  const handleClickTeam = (team: Team) => {
    userMutation.mutate({ userFields: { selectedTeamId: team.id } });
  };
  return (
    <Layout aside={renderAside}>
      {/* <h1 className="text-lg">Dashboard</h1>
      <p>Welcome to Henna!</p> */}
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <WelcomeBanner
          teamName={user?.selectedTeam?.title}
          organizationName={user?.selectedOrganization?.title}
          firstName={user?.firstName ?? user?.email ?? ""}
        ></WelcomeBanner>
      )}
      {!user && (
        <p>
          We are having trouble loading your account information. Try refreshing
          the page?
        </p>
      )}
      {(!user?.selectedOrganizationId || !user?.selectedTeamId) && (
        <JoinRequestForm />
      )}
      {/* {!user?.selectedTeamId && user?.selectedOrganizationId && (
        <div>
          <p>Next, click the team that you are a member of:</p>
          <TeamList
            handleClickTeam={handleClickTeam}
            organizationId={user.selectedOrganizationId}
            isPending={userMutation.isPending}
          />
          {userMutation.isPending && <p>We are processing your request...</p>}
          {userMutation.isError && (
            <p>There was an error processing your request.</p>
          )}
          {userMutation.data && <p>Success!</p>}
        </div>
      )} */}
    </Layout>
  );
};

export default DashboardPage;
